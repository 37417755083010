<script setup>
import { ref, onMounted, computed, inject, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Timeline from "@/components/ui/baseComponents/timeline.vue";
import InterviewRunner from "@/pages/RE/interviewOffers/InterviewRunner.vue";
import BaseModal from "@/components/ui/baseComponents/modal.vue";
import signaturepad from "@/components/ui/baseComponents/signaturepad.vue";

provide("saveAnswer", "saveAnswer");
provide("vuexModule", "InterviewProcess");
provide("type", "interview");

const emitter = inject("emitter");
const store = useStore();
const route = useRoute();
const router = useRouter();
const activeStep = ref(0);
const showModal = ref(false);
const showConfirmModal = ref(false);
var applicantStatus = ref(2);
onMounted(async () => {
  if (!store.getters["InterviewProcess/getActiveInterview"]) {
    await store.dispatch("InterviewProcess/fetchSingleInterview", {
      interview: route.params?.interviewId || 0,
    });
  }
});
const groupBy = (arr, key) => {
  const initialValue = {};
  return arr?.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};
const headsArray = [
  "About the Candidate",
  "Role Specific Questions",
  "Questions from the Candidate",
];
const lastHeads = [
  {
    title: "Adhoc Questions",
    type: "AdHocQuestions",
    name: "adhoc",
  },
  {
    title: "Candidate Questions",
    type: "AdHocQuestions",
    name: "candidate",
  },
  {
    title: "Salary & Notice",
    type: "SalaryAndNotice",
  },
  {
    title: "Outcomes & Sign Off",
    type: "FinalStep",
  },
];
const interviewSteps = computed(() => {
  const data = store.getters["InterviewProcess/getActiveInterview"];
  if (data) {
    const groupedQuestion =
      data && groupBy(data?.["interview_policies"][0]?.questions, "head");
    const steps = [];
    groupedQuestion &&
      Object.keys(groupedQuestion).map((item) => {
        if (data.application_status != "second-interview") {
          steps.push({
            title: headsArray[item - 1],
            questions: groupedQuestion[item],
          });
        }
      });
    lastHeads.map((item) => {
      steps.push(item);
    });
    steps[0].questions?.unshift({
      type: "profileCard",
    });
    return steps;
  }
  return [];
});
const completeInterview = () => {
  saveSignature(0);
};
const saveSignature = (e) => {
  emitter.emit("onSaveSignature", e);
  showModal.value = false;
  showConfirmModal.value = true;
};
const interviewCompleted = async () => {
  showConfirmModal.value = false;
  await store.dispatch("InterviewProcess/completeInterview", {
    interview: route.params.interviewId,
    progress: activeStep.value,
    is_completed: 1,
    applicant_status: applicantStatus.value,
  });
  router.push({ name: "re-interviews-offers" });
};
const applicantStatusFunc = (e) => {
  console.log("applicant status at conduct interview: ", e);
  applicantStatus.value = e;
};
</script>
<template>
  <div class="p-5 h-full flex flex-row md:flex-col">
    <div class="md:mx-4 md:p-4 h-full md:h-auto w-auto">
      <Timeline
        :totalSteps="interviewSteps"
        :activeStep="activeStep"
        :responsive="true"
      />
    </div>
    <template v-if="interviewSteps?.[activeStep]">
      <InterviewRunner
        :currentStep="interviewSteps[activeStep]"
        :activeStep="activeStep"
        :interviewSteps="interviewSteps"
        @gotoPreviousStep="activeStep--"
        @gotoNextStep="activeStep++"
        @completeInterview="completeInterview"
        @applicantStatusForConduct="applicantStatusFunc"
      />
    </template>
    <BaseModal
      v-model:show="showModal"
      :showCancel="false"
      :showConfirm="false"
    >
      <template #title>Completed Interveiw </template>
      <template #content>
        <signaturepad @saveSignature="saveSignature">
          <template #saveButton>
            <button
              class="
                bg-teal
                border border-gray-400
                font-medium
                mt-3
                px-3
                py-3
                rounded-md
                text-white
              "
            >
              save Signature
            </button>
          </template>
        </signaturepad>
      </template>
    </BaseModal>
    <BaseModal
      v-model:show="showConfirmModal"
      :showCancel="false"
      @confirm="interviewCompleted"
    >
      <template #title>Completed Interveiw </template>
      <template #content>
        <div class="my-1 sm:ml-2 md:ml-10">
          <div class="w-full pr-10">
            <label
              class="font-semibold text-grey flex pb-1"
              for="ad_hoc_question"
              >Interview Process is completed</label
            >
          </div>
        </div>
      </template>
      <template #successButton> Okay </template>
    </BaseModal>
  </div>
</template>
